<template>
  <tr>
    <td></td>
    <td>{{ item.name }}</td>
    <td>{{ item.status }}</td>
    <td>
      <input class="form-control" placeholder="Hours" v-model="item.hours" type="number" step="0.01">
    </td>
    <td>
      <textarea :readonly="!item.hours" class="form-control" placeholder="Task Note" v-model="item.full_description"
                type="text"></textarea>
    </td>
    <td>
      <AsyncSelect :disabled="!item.hours"
                   :option-selected="value => onchangeArea(value, item)"
                   placeholder="Select area"
                   :additional-query="additionalQuery"
                   v-model="areaRef"
                   :api-service="fetchAreaList"
      />
    </td>
    <td>
      <input class="form-control w-50" readonly placeholder="Conveyance" v-model="item.conveyance" type="text">
    </td>
  </tr>
</template>

<script setup>
import {defineProps, onMounted, ref, watch} from 'vue';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleCrm from "@/services/modules/crm/crm";

const areaRef = ref()
const {fetchAreaList} = handleCrm();
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
});

watch(
    () => props.item,
    (newItem) => {
      areaRef.value = {
        id: newItem.area_id,
        name: newItem.area_name,
        amount: newItem.conveyance,
      };
    }
);

const additionalQuery = ref({
  with_amount: true,
});

const onchangeArea = (value, item) => {
  item.conveyance = value.amount
  item.area_id = value.id
}

onMounted(()=>{
  areaRef.value = {
    id: props.item.area_id,
    name: props.item.area_name,
    amount: props.item.conveyance,
  };
})

</script>

<style scoped>
td {
  padding: 5px 15px;
}
</style>
