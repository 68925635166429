<template>
  <div class="">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Next Work Plan"
          :showBtn="false"
          :showAddNew="true"
          title="Work status update"
          @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="card p-2">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <label class="align-middle">Date</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="date"
              placeholder=""
              v-model="formData.date"
          >
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <label class="align-middle">Check-In time: </label>
          <input type="time" class="form-control" v-model="formData.time">
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Client: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                <i class="fas fa-search"></i>
            </span>
          </div>
          <AsyncSelect
              @onClear="onClearParty"
              :option-selected="getChangeParty"
              placeholder="Select Client"
              v-model="formData.contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
              :format-label="formatPatientLabel"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Select Project: </label>
          </div>
          <AsyncSelect
              ref="projectSelectField"
              placeholder="Select Project Director"
              v-model="formData.project"
              :api-service="fetchProjects"
              :additional-query="projectAdditionalQuery"
          />
        </div>
      </div>
      <div class="row p-1">
        <div class="col-sm-12 d-flex justify-content-between">
          <div>
            <button type="button" @click="getContactWithProjects"
                    class="btn btn-success me-1 waves-effect waves-float waves-light"
            >
              Go
            </button>
          </div>
          <div>
            <button
                :disabled="loading" @click="workStatusUpdate"
                class="btn btn-primary me-1 waves-effect waves-float waves-light"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-for="project in projects">
      <table class="table table-bordered">
        <thead>
        <tr style="font-size: 18px">
          <th colspan="3">{{ project.name }}</th>
          <th></th>
          <th class="text-right" colspan="2">Start: {{ formatToDdMmYy(project.start_date) }}</th>
          <th>End: {{ formatToDdMmYy(project.end_date) }}</th>
        </tr>
        <tr>
          <th>Group</th>
          <th>Activity Task Name</th>
          <th>Status</th>
          <th>Hours</th>
          <th>Task Note</th>
          <th>Work Station</th>
          <th>Conveyance</th>
        </tr>
        </thead>
        <tbody>
        <TableTreeBrowser :node="project.project_accounts"/>
        </tbody>
      </table>
    </div>
    <div class="card">
      <h4 v-if="!projects.length" class="text-center card-body">No project found!</h4>
    </div>
    <div class="mb-2"></div>
    <Loader v-if="loading"/>
  </div>
  <ContactDropdownFilter
      v-if="store.state.isModalOpenThree"
      @onSearchContact="onSearchContact"
      :companyRoles="companyRoles"
      :isFilterable="true"
  />
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import Loader from '@/components/atom/LoaderComponent'
import {computed, inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import handleCompanyContact from "@/services/modules/companyContact";
import TableTreeBrowser from "@/views/page/company/contact/work-status/TableTreeBrowser.vue";
import useDate from "@/services/utils/day";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleProjects from "@/services/modules/procurement/project";
import handleContact from "@/services/modules/contact";
import {useStore} from "vuex";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import handleRole from "@/services/modules/role";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

const {formatToDdMmYy} = useDate();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchContactProfiles} = handleContact();
const {fetchProjects} = handleProjects();
const {fetchContactWithProjects, projectWorkStatusUpdate} = handleCompanyContact();
const {fetchCompanyDefaultRoles} = handleRole();
const {formatPatientLabel} = useAsyncDropdownHelper();

const projectAdditionalQuery = ref({
  with_donors: 1,
  party_id: ''
})
let projectSelectField = ref(null)
let loading = ref(false)
let projects = ref([])
let companyRoles = ref([])
const navigateToAddPage = () => {
  // TODO:: next time work
}

const route = useRoute()
const store = useStore();

//computed
const companyId = computed(() => route.params.companyId);

const formData = ref({
  date: '',
  time: '',
  contact_profile_id: '',
  project: null,
  contactProfile: null,
})

const additionalContactOption = ref({
  with_mobile: 1,
  dealer_id: ''
});

const getChangeParty = (value) => {
  projectAdditionalQuery.value.party_id = value.id
  projectSelectField.value.clear()
}

const onClearParty = () => {
  projectAdditionalQuery.value.party_id = ''
  projectSelectField.value.clear()
}

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  formData.value.contactProfile = contact
}

const getCompanyRoles = async () => {
  const res = await fetchCompanyDefaultRoles(`?company_id=${route.params.companyId}&roles=customer,lead`);
  if (res.status) {
    companyRoles.value = res.data
  }
}

const workStatusUpdate = () => {
  loading.value = true
  if (!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
  const params = {
    company_id: companyId.value,
    date: formData.value.date,
    contact_profile_id: formData.value.contact_profile_id,
    projects: projects.value
  }
  projectWorkStatusUpdate(params).then(({data, status, message}) => {
    if (!status) {
      return showError(message)
    }
    showSuccess(message)
  }).catch((e) => {
    console.log(e)
  }).finally(() => loading.value = false);
}

const getContactWithProjects = () => {
  loading.value = true
  const query = getQuery()

  fetchContactWithProjects(query).then(({data, status, message}) => {
    if (!status) {
      projects.value = []
      formData.value.contact_profile_id = ''
      return showError(message)
    }
    projects.value = data.project_masters
    formData.value.contact_profile_id = data.id
  }).catch((e) => {
    console.log(e)
  }).finally(() => loading.value = false);
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&date=' + formData.value.date
  if (formData.value.contactProfile) query += `&contact_profile_id=${formData.value.contactProfile.id}`;
  if (formData.value.project) query += `&project_id=${formData.value.project.id}`;
  return query
}

onMounted(() => {
  formData.value.date = new Date().toISOString().split('T')[0]
  getContactWithProjects();
  getCompanyRoles()
})
</script>
